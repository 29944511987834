// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider_nodots__Ib9X1{\r\n height: 250px;\r\n}\r\n\r\n.slider_category_text__eGl\\+r{\r\n margin-left: 5px;\r\n\r\n font-family: 'Montserrat';\r\n}\r\n\r\n\r\n.slider_upslider__9\\+z0A{\r\n margin-top: 40px;\r\n font-family: 'Montserrat';\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/UI/slider.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,gBAAgB;;CAEhB,yBAAyB;AAC1B;;;AAGA;CACC,gBAAgB;CAChB,yBAAyB;AAC1B","sourcesContent":[".nodots{\r\n height: 250px;\r\n}\r\n\r\n.category_text{\r\n margin-left: 5px;\r\n\r\n font-family: 'Montserrat';\r\n}\r\n\r\n\r\n.upslider{\r\n margin-top: 40px;\r\n font-family: 'Montserrat';\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nodots": "slider_nodots__Ib9X1",
	"category_text": "slider_category_text__eGl+r",
	"upslider": "slider_upslider__9+z0A"
};
export default ___CSS_LOADER_EXPORT___;
