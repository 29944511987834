// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Category_category__3yR00{\r\n    text-decoration: none;\r\n    color:var(--tg-theme-text-color);\r\n    font-weight: normal;\r\n    margin-top: 11px;\r\n    margin-right: 15px;\r\n    margin-bottom: 30px;\r\n    font-size: 12px;\r\n    padding: 15px 5px 5px 5px;\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    width: 110px;\r\n    height: 155px;\r\n    border-radius: 10px;\r\n    align-items: center;\r\n    text-align: center;\r\n    overflow-x: hidden;\r\n    border: 1px solid black;\r\n\r\n\r\n}\r\n\r\n\r\n\r\n\r\n\r\n.Category_category__3yR00>  img{\r\n\r\n    text-align: center;\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 3px;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/Category/Category.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gCAAgC;IAChC,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,oDAAoD;IACpD,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;;;AAG3B;;;;;;AAMA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".category{\r\n    text-decoration: none;\r\n    color:var(--tg-theme-text-color);\r\n    font-weight: normal;\r\n    margin-top: 11px;\r\n    margin-right: 15px;\r\n    margin-bottom: 30px;\r\n    font-size: 12px;\r\n    padding: 15px 5px 5px 5px;\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    width: 110px;\r\n    height: 155px;\r\n    border-radius: 10px;\r\n    align-items: center;\r\n    text-align: center;\r\n    overflow-x: hidden;\r\n    border: 1px solid black;\r\n\r\n\r\n}\r\n\r\n\r\n\r\n\r\n\r\n.category>  img{\r\n\r\n    text-align: center;\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 3px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category": "Category_category__3yR00"
};
export default ___CSS_LOADER_EXPORT___;
