// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthButton_AuthButton__maQ4S{\r\n\r\n}\r\n\r\n.AuthButton_AuthButton__maQ4S button{\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    color: black;\r\n    background-color: rgba(215, 125, 72, 1);\r\n    border-radius: 10px;\r\n    padding: 3vw;\r\n    height: 100%;\r\n    width: 100%;\r\n    border: 0;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Button/AuthButton/AuthButton.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,uCAAuC;IACvC,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,SAAS;AACb","sourcesContent":[".AuthButton{\r\n\r\n}\r\n\r\n.AuthButton button{\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    color: black;\r\n    background-color: rgba(215, 125, 72, 1);\r\n    border-radius: 10px;\r\n    padding: 3vw;\r\n    height: 100%;\r\n    width: 100%;\r\n    border: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthButton": "AuthButton_AuthButton__maQ4S"
};
export default ___CSS_LOADER_EXPORT___;
