// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShopingCartList_shoppingCartList__TWEWd{\r\n    padding-top: 40px;\r\n    background: var(--tg-theme-bg-color);\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n\r\n}\r\n\r\n.ShopingCartList_makeOrder__xBwul{\r\n    background: rgba(215, 125, 72, 1);\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    width: 90%;\r\n    margin: 5%;\r\n    padding: 3vw;\r\n    border-radius: 10px;\r\n    border: None;\r\n}\r\n\r\n.ShopingCartList_topay__hEN8U{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-top: 5vw;\r\n    color: rgba(19, 189, 50, 1)\r\n}", "",{"version":3,"sources":["webpack://./src/components/ShopingCartList/ShopingCartList.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oCAAoC;IACpC,iCAAiC;IACjC,yBAAyB;;AAE7B;;AAEA;IACI,iCAAiC;IACjC,iCAAiC;IACjC,yBAAyB;IACzB,iBAAiB;IACjB,UAAU;IACV,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf;AACJ","sourcesContent":[".shoppingCartList{\r\n    padding-top: 40px;\r\n    background: var(--tg-theme-bg-color);\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n\r\n}\r\n\r\n.makeOrder{\r\n    background: rgba(215, 125, 72, 1);\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    width: 90%;\r\n    margin: 5%;\r\n    padding: 3vw;\r\n    border-radius: 10px;\r\n    border: None;\r\n}\r\n\r\n.topay{\r\n    font-weight: bold;\r\n    text-align: center;\r\n    margin-top: 5vw;\r\n    color: rgba(19, 189, 50, 1)\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shoppingCartList": "ShopingCartList_shoppingCartList__TWEWd",
	"makeOrder": "ShopingCartList_makeOrder__xBwul",
	"topay": "ShopingCartList_topay__hEN8U"
};
export default ___CSS_LOADER_EXPORT___;
