// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessPage_success__ET6mq{\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.SuccessPage_success_btn__uD02D{\r\n    border-radius: 10px;\r\n    font-weight: bold;\r\n    margin-top: 10vw;\r\n    padding: 3vw;\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n    background-color: rgba(215, 125, 72, 1);\r\n\r\n}\r\n\r\n.SuccessPage_success__ET6mq img{\r\n    display: block;\r\n    height: 20vw;\r\n    width: 20vw;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-bottom: 5vw;\r\n}", "",{"version":3,"sources":["webpack://./src/components/SuccessPage/SuccessPage.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,iCAAiC;IACjC,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,0CAA0C;AAC9C;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,iCAAiC;IACjC,yBAAyB;IACzB,uCAAuC;;AAE3C;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".success{\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.success_btn{\r\n    border-radius: 10px;\r\n    font-weight: bold;\r\n    margin-top: 10vw;\r\n    padding: 3vw;\r\n    color: var(--tg-theme-text-color);\r\n    font-family: 'Montserrat';\r\n    background-color: rgba(215, 125, 72, 1);\r\n\r\n}\r\n\r\n.success img{\r\n    display: block;\r\n    height: 20vw;\r\n    width: 20vw;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-bottom: 5vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "SuccessPage_success__ET6mq",
	"success_btn": "SuccessPage_success_btn__uD02D"
};
export default ___CSS_LOADER_EXPORT___;
