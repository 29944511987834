// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessAuth_success__Gj4Tk{\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.SuccessAuth_success__Gj4Tk img{\r\n    display: block;\r\n    height: 20vw;\r\n    width: 20vw;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-bottom: 5vw;\r\n}\r\n\r\n.SuccessAuth_AuthButton__p2GPJ{\r\n    margin-top: 4vw;\r\n    display: flex;\r\n\r\n    flex-direction: column;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/SuccessAuth/SuccessAuth.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,iCAAiC;IACjC,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;IACjB,0CAA0C;AAC9C;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,aAAa;;IAEb,sBAAsB;AAC1B","sourcesContent":[".success{\r\n    padding: 14vw 5vw 10vw 5vw;\r\n    display: flex;\r\n    color: var(--tg-theme-text-color);\r\n    flex-direction: column;\r\n    width: 100%;\r\n    font-family: 'Montserrat';\r\n    font-weight: bold;\r\n    background-color: var(--tg-theme-bg-color);\r\n}\r\n\r\n.success img{\r\n    display: block;\r\n    height: 20vw;\r\n    width: 20vw;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-bottom: 5vw;\r\n}\r\n\r\n.AuthButton{\r\n    margin-top: 4vw;\r\n    display: flex;\r\n\r\n    flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "SuccessAuth_success__Gj4Tk",
	"AuthButton": "SuccessAuth_AuthButton__p2GPJ"
};
export default ___CSS_LOADER_EXPORT___;
